#Intro {
  width: calc(clamp(260px, 700px, 70vw));
  min-height: 260px;
  border: 1px solid var(--color-text);
  color: var(--color-text);

  position: relative;
  background-color: var(--color-bg);

  .header {
    font-size: var(--size-big);
    font-weight: 600;
    // color: var(--color-bg);
    background-color: var(--color-bg);
    border: 1px solid var(--color-text);
    padding: 10px 20px;
    width: 225px;
    max-width: 50vw;

    position: absolute;
    left: 35px;
    top: -25px;
  }

  .close-button {
    font-size: 2rem;

    position: absolute;
    top: -1.5rem;
    right: 0;
    height: 1rem;
  }

  .prev-button,
  .next-button {
    font-size: 3rem;
    color: var(--color-bg);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2rem;
  }

  .prev-button {
    left: calc(max(-9vw, -2.5rem));
    transform: translateY(-50%) rotate(180deg);
  }
  .next-button {
    right: calc(max(-9vw, -2.5rem));
  }

  .intro-body {
    padding: 50px 35px;

    .carousel-body {
      white-space: pre-wrap;
    }
  }

  #IntroProgress {
    position: absolute;
    left: 10px;
    bottom: -20px;
  }
}

#IntroProgress {
  display: flex;
  gap: 7.5px;
  .dot {
    cursor: pointer;
    width: 10px;
    height: 10px;

    border: 1px solid var(--color-bg);
    border-radius: 50%;

    &.active {
      background-color: var(--color-bg);
    }
  }
}
