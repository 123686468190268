#Disclaimer {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  background-color: var(--color-bg);
  outline: 1px solid var(--color-text);

  display: flex;
  flex-direction: column;

  .section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center {
    justify-content: center;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;

    .logo {
      pointer-events: none;
      width: 100%;
      flex: 1;
      position: relative;

      .lines {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
      }
    }

    .subtitle {
      text-align: center;
    }
  }
  .bottom {
    // justify-content: space-around;

    .disclaimer {
      padding: 5px 50px;
      text-align: center;

      background-color: var(--color-text);
      color: var(--color-bg);

      margin: 50px var(--page-padding);
    }

    .enter-button {
      cursor: pointer;
      text-transform: none;
    }
  }

  &.visited {
    pointer-events: none;

    transform: translateY(-100%);
    transition: transform 0.75s ease-out;
  }
}
