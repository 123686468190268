#ThankYou {
  padding-top: 50px;

  .back-button {
    margin-left: var(--page-padding);
  }

  .tabs {
    box-sizing: border-box;
    padding: 0 var(--page-padding);

    height: calc(var(--height-tab-active) + 1rem);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-end;

    .tab {
      text-transform: uppercase;
    }
    .empty {
      flex: 2;
      border-bottom: 1px solid var(--color-text);
    }
  }

  .text {
    padding: 0 var(--page-padding);
    flex: 1;
    padding-top: 100px;
    padding-bottom: 150px;
    display: flex;
    align-items: center;
    white-space: pre-wrap;

    // height: 100%;
    .side,
    .middle {
      flex: 1;
    }

    @media only screen and (max-width: 1000px) {
      .side {
        flex: 0 1 10vw;
      }
    }

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
