#Home {
  padding: 0 var(--page-padding);
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 75px;

  .tabs {
    box-sizing: border-box;

    height: calc(var(--height-tab-active) + 1rem);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-end;

    .tab {
      height: var(--height-tab);
    }
    .empty {
      flex: 1;
      border-bottom: 1px solid var(--color-text);
      padding: calc(min(0.5vw, 20px));
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .top {
    .tab {
      text-transform: uppercase;
    }
    .text {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      gap: 2em;
      align-items: center;
      text-align: center;
    }
    margin-bottom: 100px;
  }

  .bottom {
    flex: 1 0 50vh;
    .tabs {
      .tab {
        white-space: pre-wrap;
        font-size: var(--font-size);
        justify-content: center;
        text-align: center;
        font-weight: bold;
        // height: 39px;
      }
      margin-bottom: 50px;
    }
    margin-bottom: 100px;
  }

  .context {
    font-weight: 600;
    text-align: center;
    margin: 25px;
  }

  .bottom-text {
    text-align: center;
  }

  .see-all-button {
    font-size: var(--size-big);
    text-transform: none;
    margin-top: 50px;
  }

  #PreviewEvents {
    box-sizing: border-box;
    width: 100%;
    align-self: center;

    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    row-gap: 50px;

    #EventPreview {
      flex: 1;
      max-width: 300px;
    }

    @media only screen and (max-width: 1600px) {
      flex-wrap: wrap;
      max-width: 1200px;
      #EventPreview {
        flex: 0 1 30%;
      }
    }
    @media only screen and (max-width: 750px) {
      flex-direction: column;
      #EventPreview {
        flex: 0;
        width: 300px;
        max-width: 100%;
      }
    }

    margin-bottom: 30px;
  }
}
