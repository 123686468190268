#Container {
  overflow: hidden;
}

#App {
  overflow: hidden;

  .transition-root {
    position: absolute;
    top: 0;

    .scroll {
      background-color: var(--color-bg);
      overflow: auto;
      width: 100vw;
      height: 100vh;
      outline: 1px solid var(--color-text);

      .body {
        min-height: calc(100vh - 145px); //145px = header height
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }

    .page {
      width: 100%;
      position: absolute;
      border-top: 1px solid var(--color-text);
      z-index: -1;
      height: 0px;
    }
    .page3 {
      background-color: var(--color-bg);
      height: 100%;
    }
  }
}

.overline,
.link {
  position: relative;
  white-space: nowrap;
  &::before {
    content: "";
    width: 100%;
    height: var(--line-thick);
    background-color: var(--color-accent);
    position: absolute;
    left: 0;
    top: 0;
  }
}

.underline {
  &::before {
    top: auto;
    bottom: 0;
  }
}

.link {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 2.5em;
  vertical-align: middle;
  display: inline-block;
}

.text-button {
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}

.button {
  user-select: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: var(--color-text);
}

@media only screen and (max-width: 1000px) {
  .desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 1001px) {
  .mobile {
    display: none !important;
  }
}

.transition-down-enter,
.transition-down-exit,
.transition-up-enter,
.transition-up-exit {
  position: absolute;
  top: 0;
  width: 100%;
}

#EventTransition {
  &.transition-enter {
    transform: translateY(150%);

    .page1 {
      top: -5%;
    }
    .page2 {
      top: -20%;
    }
    .page3 {
      top: -30%;
    }
  }

  &.transition-enter-active {
    transition: transform 1.25s ease-out;
    transform: translateY(0%);
    z-index: 2;

    .page {
      transition: top 1s ease-in;
    }
    .page1 {
      top: -1%;
    }
    .page2 {
      top: -2%;
    }
    .page3 {
      top: -3%;
    }
  }
}

.tab {
  font-size: var(--size-big);
  border-top-left-radius: var(--tab-border-radius);
  border-top-right-radius: var(--tab-border-radius);

  flex: 1;
  min-width: max-content;
  padding: 7px 20px 7px 20px;
  // padding-left: 20px;

  color: var(--color-bg);
  background-color: var(--color-text);
  border: 1px solid var(--color-bg);
  border-bottom: none;

  display: flex;
  line-height: var(--height-tab);

  &.active {
    color: var(--color-text);
    background-color: var(--color-bg);
    border: 1px solid var(--color-text);
    border-bottom: none;
  }
}

.back-button {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 0px;

  &::before {
    display: inline-block;
    content: "<";
    margin-right: 8px;
    font-size: 1.25rem;
    transform: translateY(1px);
  }
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
