#Header {
  padding: 0 var(--page-padding);
  display: flex;
  flex-direction: column;

  .top {
    height: 100px;
    display: flex;
    align-items: center;

    .logo {
      height: 75px;
      & > div {
        height: 100%;
        max-width: 80vw;
      }
    }
  }
  .bottom {
    display: flex;

    .section {
      flex: 1;
    }
    .left {
      .blue-line {
        height: var(--line-thick);
        width: calc(100% - 250px);
        background-color: var(--color-accent);
        margin-left: auto;
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .link {
        font-size: var(--size-big);
        margin: 0 20px;
        min-width: 100px;
        text-align: center; //TODO: maybe not center? looks cool both ways
      }
    }
  }
}
