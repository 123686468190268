#DataAndMethods {
  flex: 1;
  padding-top: 50px;

  .back-button {
    margin-left: var(--page-padding);
  }

  .tabs {
    box-sizing: border-box;
    padding: 0 var(--page-padding);

    height: calc(var(--height-tab-active) + 1rem);
    width: 100%;
    display: flex;
    align-items: flex-end;

    overflow: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .text {
    padding: 0 var(--page-padding);
    flex: 1;
    padding-top: 100px;
    padding-bottom: 150px;
    display: flex;
    align-items: center;
    white-space: pre-wrap;

    // height: 100%;
    .side,
    .middle {
      flex: 1;
    }

    @media only screen and (max-width: 1000px) {
      .side {
        flex: 0 1 10vw;
      }
    }

    iframe {
      border: none;
      width: 100%;
      height: 50vh;
    }

    ol {
      margin: 0;
      list-style-type: upper-latin;
      padding-left: 2em;
      white-space: normal;
    }

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
