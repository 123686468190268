#Event {
  --video-size: calc(min(60vh, 75vw));
  --maps-size: calc(min(80vh, 100vw));

  width: 100%;
  margin-bottom: 100px;

  // display: flex;
  // flex-direction: column;

  .back-button {
    padding: 5px 10px;
  }

  .event-body {
    position: relative;
    padding: 50px 0 25px 0;
    min-height: var(--maps-size);

    .background-streetview {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: gray;

      .noise-overlay {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-image: url("/assets/noise_tile_15percent_opacity.gif");
        background-repeat: repeat;
        opacity: 0.15;
      }
    }

    .foreground {
      height: 100%;
      position: relative;

      display: flex;
      justify-content: center;
      z-index: 1;

      .left,
      .right {
        flex: 1;
      }

      .left,
      .center {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .victim {
        width: var(--video-size);
        min-height: var(--video-size);
        border: 5px solid var(--color-bg);
        position: relative;

        .foreground-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        .video:not(.playing) {
          visibility: hidden;
        }

        .controls {
          display: flex;
          align-items: center;
          gap: 5px;

          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 70%);

          .mute,
          .captions,
          .play,
          .stop {
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .mute,
          .captions {
            color: var(--color-bg);
            width: 35px;
            height: 35px;
            background-color: var(--color-text);
          }

          .progress-ring {
            pointer-events: none;
            user-select: none;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            transform: rotate(-90deg);
            transform-origin: 50% 50%;

            circle {
              stroke: var(--color-text);
              r: calc(25% - 2.5px);
              stroke-width: 5;
            }
          }

          .play,
          .stop {
            color: var(--color-text);
            width: 50px;
            height: 50px;
            // border: 3px solid var(--color-text);

            &[hidden] {
              display: none;
            }
          }

          .stop {
            .stop-icon {
              width: 15px;
              height: 15px;
              background-color: var(--color-text);
            }
          }
        }

        margin-bottom: 50px;
      }

      .transcript {
        border: 1px solid var(--color-text);
        background-color: var(--color-bg);
        padding: 5px 25px;
        white-space: pre-wrap;

        width: 300px;
        max-width: calc(100vw - var(--page-padding));

        height: 10vh;
        box-sizing: border-box;
        overflow: hidden;
        .text {
          transition: transform 1s;
        }
      }

      @media only screen and (max-width: 750px) {
        .transcript {
          margin-bottom: -40px;
        }
      }
    }
  }

  .view-all-button {
    align-self: center;
    margin-bottom: 30px;
  }
}

#DetailsDesktop {
  max-width: 300px;
  margin: 5px;
  height: var(--video-size);
  display: flex;
  align-items: center;

  .title,
  .arrow {
    display: none;
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-left: 5px solid var(--color-bg);
    padding-left: 5px;
    min-width: 250px;
    text-transform: capitalize;
    .detail {
      padding: 5px;
      background-color: var(--color-bg);
    }
  }
}

#DetailsMobile {
  max-width: calc(100vw - var(--page-padding) * 2);
  margin: 15px auto;
  box-sizing: border-box;
  margin-top: 30px;
  border: 1px solid var(--color-text);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: max-content;
  padding: 0 15px;

  .title {
    font-weight: 600;
    margin: 10px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .arrow {
    font-weight: 600;
    line-height: 2rem;
    vertical-align: middle;
    font-size: 2rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  .arrow-down {
    transform: scaleY(0.75);
  }
  .arrow-up {
    margin-top: 10px;
    transform: rotate(180deg) scaleY(0.75);
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: calc(100vw - 60px);
    text-transform: capitalize;
    .detail {
      border: 1px solid var(--color-text);
      padding: 5px;
    }
  }
}

#Tabs {
  height: calc(var(--height-tab-active) + 1rem);
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-bottom: 3px solid var(--color-text);

  overflow: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    width: 30vw;
    min-width: max-content;
    margin-right: -5vw;
    padding: 7px;
    padding-right: 8vw;

    transition: line-height 0.1s;

    &:hover {
      line-height: var(--height-tab-active);
    }
    &.active {
      line-height: var(--height-tab-active);
    }

    .pin {
      margin-right: 5px;
      width: 19px;
    }
  }
}

#EventPreviewCarousel {
  margin-top: 50px;
  margin-bottom: 30px;

  padding-top: 20px;

  width: 100%;
  position: relative;

  .blue-line {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    height: var(--line-thick);
    width: 15%;
    background-color: var(--color-accent);
  }
  .container {
    width: max-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 2vw;

    .next-button,
    .prev-button {
      width: 1.5vw;
      margin-bottom: 1%;

      &[hidden] {
        display: inherit;
        visibility: hidden;
      }
    }
    .prev-button {
      transform: rotate(180deg);
    }

    .carousel-container {
      width: max-content;
      display: flex;
      justify-content: center;
      gap: 1vw;
      position: relative;

      .black-line {
        top: 20px;
        left: 0;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: var(--color-text);
      }
      .carousel-item {
        flex: 1;
        width: 15vw;
        // height: 15vw;

        &.active {
          .media {
            // filter: brightness(1.5);
            opacity: 0.5;
          }
        }
      }
    }
  }
}

#EventPreviewList {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  .list-item {
    width: 300px;
    max-width: 100%;

    &.active {
      .media {
        // filter: brightness(1.5);
        opacity: 0.5;
      }
    }
  }
}

#EventPreviewSmall {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  // height: 100%;

  .date,
  .neighbourhood {
    width: 100%;
    text-align: center;
    overflow: hidden;
    max-width: 15vw;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .media {
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 15vw;

    .background-image {
      min-width: 0;
      background-color: gray;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .foreground {
      position: relative;
      border: 5px solid var(--color-bg);
      width: 85%;
      height: 85%;

      .foreground-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    margin-bottom: 10px;
  }
}
