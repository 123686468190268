#All {
  padding: 0 var(--page-padding);
  padding-top: 80px;

  .tabs {
    box-sizing: border-box;

    height: calc(var(--height-tab-active) + 1rem);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-end;

    .tab {
      height: var(--height-tab);
    }
    .empty {
      flex: 1;
      border-bottom: 1px solid var(--color-text);
      padding: calc(min(0.5vw, 20px));
    }
  }

  .previewEvents {
    position: relative;
    padding: 70px 120px;
    display: flex;
    flex-flow: wrap;
    gap: 25px;
    row-gap: 50px;

    @media only screen and (max-width: 1000px) {
      justify-content: center;
    }

    #EventPreviewMedium {
      min-width: 250px;
      max-width: 100%;
    }

    .month {
      font-size: var(--size-big);
      position: absolute;
      left: 1.5em;
      top: 0;
      transform: translateY(-50%);
      border: 1px solid var(--color-text);
      background-color: var(--color-bg);
      padding: 2px 20px;
      width: 250px;
      max-width: 80%;
    }
  }

  #PreviewDeadEvents {
    position: relative;
    background-color: var(--color-text);
    .past {
      font-weight: bold;
      color: var(--color-bg);
      text-transform: uppercase;
      transform: rotate(-90deg);
      transform-origin: top left;
      position: absolute;
      left: 5px;
      bottom: 5px;
    }
  }

  #PreviewEvents {
    position: relative;
    .future {
      font-weight: bold;
      text-transform: uppercase;
      transform: rotate(-90deg) translateX(calc(-100% - 1.5em));
      transform-origin: top left;
      position: absolute;
      left: 5px;
      top: 5px;
    }
    .previewEvents {
      border-top: 1px solid var(--color-text);
    }
  }
}
