/* Aglet Mono Semibold

font-family: aglet-mono, mono;
font-weight: 600;
font-style: normal;

Aglet Mono Regular

font-family: aglet-mono, mono;
font-weight: 400;
font-style: normal; */


:root {
  --size-normal: 14px;
  --size-big: 18px;

  --color-bg: #F4EBD4;
  --color-accent: #0000FF;
  --color-text: #1A1A1A;
  
  --height-tab: 1.5rem;
  --height-tab-active: 2rem;

  --tab-border-radius: 12px;
  --line-thick: 5px;
  --page-padding: 25px;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: aglet-mono, mono;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--size-normal);

  background-color: var(--color-bg);
}
