#Footer {
  @media only screen and (min-width: 500px) {
    margin: 0 var(--page-padding);
  }

  .tabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .tab {
      border-color: var(--color-text);
      text-transform: uppercase;
    }
    .empty {
      flex: 1;
    }
  }
  .footer-body {
    background-color: var(--color-text);

    padding-top: 100px;
    display: flex;
    flex-direction: column;
    position: relative;

    #Intro {
      margin: 0 auto;
    }

    .link {
      margin: 50px auto;

      text-transform: none;
      color: var(--color-bg);
      &::before {
        background-color: var(--color-bg);
      }
      position: relative;
      &::after {
        right: -1em;
        position: absolute;
        content: ">";
      }
    }

    .footer-bottom-text {
      // position: absolute;
      margin-bottom: 15px;
      margin-left: 25px;
      margin-right: 25px;
      color: var(--color-bg);
    }
  }
}
