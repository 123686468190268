#EventPreviewMedium {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  position: relative;

  .city {
    // font-size: var(--size-big);

    box-sizing: border-box;
    width: 100%;
    max-width: 80%;
    text-align: center;

    .pin {
      position: absolute;
      left: 7.5%;
      top: 0px;

      height: calc(100% - 6px);
    }

    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
    position: relative;
    z-index: 1;
    background-color: var(--color-bg);
    border: 1px solid var(--color-text);
    min-width: 66%;

    margin: 0 auto;
    padding: 5px 10px;
    text-align: center;
    margin-bottom: -10px;
  }

  .date-and-time {
    text-align: center;

    box-sizing: border-box;
    width: 100%;
    max-width: 80%;

    background-color: var(--color-bg);
    border: 1px solid var(--color-text);
    white-space: nowrap;
    position: absolute;
    display: inline-block;
    padding: 5px 10px;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 100%);
  }

  .media {
    width: 100%;
    position: relative;
    aspect-ratio: 1 / 1;

    border: 1px solid var(--color-text);
    display: flex;
    justify-content: center;
    align-items: center;

    .background {
      overflow: hidden;
      align-self: flex-start;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;

      .background-preview {
        width: 100%;
        height: 110%;
      }
      .noise-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-image: url("/assets/noise_tile_15percent_opacity.gif");
        background-repeat: repeat;
        opacity: 0.15;
      }
    }
    .foreground {
      position: relative;
      border: 5px solid var(--color-bg);
      margin: 7.5%;
      width: 100%;
      aspect-ratio: 1 / 1;

      .foreground-content {
        top: 0;
        left: 0;
      }

      .foreground-preview {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: darkgray;
      }
    }
  }
  margin-bottom: 20px;
}
